import styles from './FileInput.module.scss';
import { useRef, useEffect, DragEvent, ChangeEvent, MouseEvent, KeyboardEvent, ReactElement, ReactNode } from 'react';
import { SimpleText } from 'components/sanaText';

type CommonProps = {
  id: string;
  disabled?: boolean;
};

type SingleFileProps = CommonProps & {
  value?: File | null;
  onChange: (value?: File) => void;
  multiple?: false;
};

type MultipleFilesProps = CommonProps & {
  value?: FileList | null;
  onChange: (value?: FileList) => void;
  multiple: true;
};

type Props = CommonProps & {
  value?: File | FileList | null;
  onChange: (value?: any) => void;
  multiple?: boolean;
  textKey?: string;
};

function FileInput({ id, value, onChange, multiple, disabled }: SingleFileProps): ReactElement;
function FileInput({ id, value, onChange, multiple, disabled }: MultipleFilesProps): ReactElement;
function FileInput({ id, value, onChange, multiple, disabled, textKey }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length)
      return;

    onChange(multiple ? e.target.files : e.target.files[0]);
  };

  const onFileClear = (e: MouseEvent<HTMLSpanElement> | KeyboardEvent<HTMLSpanElement>) => {
    e.preventDefault();
    inputRef.current!.value = '';
    onChange();
  };

  const onCancelBtnKeyDown = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
      onFileClear(e);
    }
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.currentTarget.classList.remove(styles.dragOver);
    onChange(multiple ? e.dataTransfer!.files : e.dataTransfer!.files[0]);
  };

  useEffect(() => {
    inputRef.current!.value = '';
  }, [value]);

  let label: ReactNode;
  if (value) {
    if ('length' in value) {
      if (value.length > 1) {
        label = <SimpleText textKey="FileUploader_SelectedFilesCount" formatWith={[value.length]} />;
      } else {
        const selectedFileName = value[0]?.name || null;
        label = <span title={selectedFileName}>{selectedFileName}</span>;
      }
    } else {
      label = <span title={value.name}>{value.name}</span>;
    }
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={`${styles.container} ${disabled ? styles.disabled : ''}`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <input
        ref={inputRef}
        id={id}
        type="file"
        multiple={multiple}
        disabled={disabled}
        onChange={onFileChange}
        className="visually-hidden"
      />
      <label htmlFor={id}>
        <span className={styles.labelWrapper}>
          {value
            ? label
            : (
              <>
                <strong className={styles.labelText}><SimpleText textKey={textKey || "FileUploader_ChooseAFile"} /></strong>
                <span className={styles.dragText}> <SimpleText textKey="FileUploader_DragAFile" /></span>
              </>
            )
          }
        </span>
        {value && (
          <span
            role="button"
            onClick={onFileClear}
            onKeyDown={onCancelBtnKeyDown}
            tabIndex={0}
            className={styles.btnCancel}
          >
            <SimpleText textKey="ButtonText_Cancel" />
          </span>
        )}
      </label>
    </div>
  );
}

export default FileInput;

function handleDragOver(e: DragEvent<HTMLDivElement>) {
  e.preventDefault();
}

function handleDragEnter(e: DragEvent<HTMLDivElement>) {
  e.preventDefault();
  e.currentTarget.classList.add(styles.dragOver);
}

function handleDragLeave(e: DragEvent<HTMLDivElement>) {
  !e.currentTarget.contains(e.relatedTarget as Node | null) && e.currentTarget.classList.remove(styles.dragOver);
}
