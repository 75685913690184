exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Password_password{position:relative}.Password_password.Password_allow-unmasking input{padding-right:5.22em}.Password_password.Password_allow-unmasking input::-ms-reveal{display:none}.Password_password button{align-items:center;background:none;border:none;bottom:0;cursor:pointer;display:flex;height:2.61em;padding:0;padding:0 .75em;position:absolute;right:0;top:0}.Password_password button img,.Password_password button svg{flex-shrink:0;font-size:1.5em;max-height:1.85em;max-width:1.85em;min-width:28px;padding-right:6px}.Password_password button .Password_btn-text{font-size:.825em;min-width:30px;text-decoration:underline}", ""]);

// exports
exports.locals = {
	"password": "Password_password",
	"allow-unmasking": "Password_allow-unmasking",
	"allowUnmasking": "Password_allow-unmasking",
	"btn-text": "Password_btn-text",
	"btnText": "Password_btn-text"
};