import inputStyles from './Input.module.scss';
import styles from './Password.module.scss';
import { type Ref, useState, useRef } from 'react';
import { VisibilityOnIcon, VisibilityOffIcon } from '../icons';
import { useSimpleTexts } from 'components/sanaText';

export type Props = {
  className?: string | null;
  innerRef?: Ref<HTMLDivElement> | null;
  inputRef?: Ref<HTMLInputElement>;
  allowUnmasking?: boolean;
  id: string;
} & Omit<JSX.IntrinsicElements['input'], 'className' | 'type' | 'key' | 'id'>;

const Password = ({
  className,
  innerRef = null,
  inputRef,
  value,
  allowUnmasking,
  'aria-describedby': ariaDescribedBy,
  id,
  ...attributes
}: Props) => {
  const [passwordHidden, setPasswordHidden] = useState(true);
  const prevPasswordHiddenRef = useRef(passwordHidden);
  const [
    ariaShowPassword,
    ariaPasswordVisible,
    ariaPasswordHidden,
    ariaPasswordShow,
    ariaPasswordHide,
  ] = useSimpleTexts([
    'Aria_ShowPassword',
    'Aria_Password_Visible',
    'Aria_Password_Hidden',
    'Aria_Password_Show',
    'Aria_Password_Hide',
  ]).texts;

  const descriptionId = id + '_dsc';
  const mergedAriaDescribedBy = allowUnmasking
    ? ariaDescribedBy
      ? `${ariaDescribedBy} ${descriptionId}`
      : descriptionId
    : ariaDescribedBy;

  let autoFocus = false;
  if (prevPasswordHiddenRef.current !== passwordHidden) {
    autoFocus = true;
    prevPasswordHiddenRef.current = passwordHidden;
  }

  return (
    <div
      className={`${inputStyles.tbx} ${styles.password} ${allowUnmasking ? styles.allowUnmasking : ''} ${className || ''}`}
      ref={innerRef}
      role="presentation"
    >
      <input
        {...attributes}
        aria-describedby={mergedAriaDescribedBy}
        ref={inputRef}
        value={value === null ? '' : value}
        id={id}
        type={passwordHidden ? 'password' : 'text'}
      />
      {allowUnmasking && (
        <>
          <span className="visually-hidden" id={descriptionId}>
            {passwordHidden ? ariaPasswordHidden : ariaPasswordVisible}
          </span>
          <button
            // Button should be re-created and focused when password visibility state changes to workaround Voiceover does not read changes issue.
            key={`${passwordHidden}`}
            autoFocus={autoFocus}
            aria-label={ariaShowPassword}
            aria-pressed={!passwordHidden}
            aria-controls={id}
            type="button"
            onClick={() => setPasswordHidden(prev => !prev)}
          >
            {passwordHidden ? <VisibilityOffIcon /> : <VisibilityOnIcon /> }
            <span className={styles.btnText}>
              {passwordHidden ? ariaPasswordShow : ariaPasswordHide}
            </span>
          </button>
        </>
      )}
    </div>
  );
};

export default Password;
