import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { Password } from 'components/primitives/form';

const PasswordField = props => (
  <Field
    autoComplete="off"
    as={Password}
    {...props}
  />
);

PasswordField.propTypes = fieldPropTypes;

export default PasswordField;
